import { themes } from 'kb-shared';
import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 560px;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > button {
    margin: 28px 0;
  }
`;

export const Form = styled.form`
  width: 100%;
`;

export const SubTitleContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    margin-top: 4px;
    margin-bottom: 4px;
  }
`;

export const InstructionText = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  margin-bottom: 12px;

  > div {
    width: 100%;
  }
`;

export const LegalAgreementsWrapper = styled.div`
  margin-top: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 8px;
`;
