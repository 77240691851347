import React, { useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../assets/icons/close-icon.svg';
import { ReactComponent as Left } from '../assets/icons/current_cycle_left.svg';
import { ReactComponent as Right } from '../assets/icons/current_cycle_right.svg';

interface Props {
  children?: React.ReactNode;
}
const MobileBanner = ({ children }: Props) => {
  const [show, setShow] = useState(true);

  return show ? (
    <NightBanner>
      <div>{children}</div>
      <Flex>
        <Left height={34} width={15} />
        <WhiteCloseIcon onClick={() => setShow(false)} />
        <Right height={34} width={15} />
      </Flex>
    </NightBanner>
  ) : null;
};

const NightBanner = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk}
  background: ${({ theme }) => theme.colors.neutral.primaryNavy};
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  top: 0;
  width: 100%;
`;

const WhiteCloseIcon = styled(CloseIcon)`
  g {
    fill: white;
  }
`;
const Flex = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export default MobileBanner;
