import React from 'react';
import { useHistory } from 'react-router-dom';

import { CalendarPlus } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import { themes } from 'kb-shared';
import { CustomList } from 'screens/Dashboard/CustomWidget/Components/CustomList';
import {
  ButtonsContainer,
  IconContainer,
  IllustratedBackgroundWidget,
  WidgetTitleContainer
} from 'screens/Dashboard/Widgets/Widgets.styled';
import { pageUrl } from 'utilities/pageUrl';

import {
  BookAppointmentInfoContainer,
  BookAppointmentInfoTitleContainer
} from './BookAppointmentWidget.styled';
import {
  componentDescription,
  componentTitle,
  getAppointmentsOfferString
} from './BookAppointmentWidget.utils';

export const BookAppointmentWidget: React.FC = () => {
  const history = useHistory();

  return (
    <IllustratedBackgroundWidget $variant={9}>
      <IconContainer $iconWidth={42} $iconHeight={48}>
        <CalendarPlus type="solid" />
      </IconContainer>
      <WidgetTitleContainer>
        <Text>{componentTitle}</Text>
      </WidgetTitleContainer>
      <BookAppointmentInfoTitleContainer>
        <Text color={themes.colors.neutral.primaryNavy} fontStyle="medium" size="lg">
          {componentDescription}
        </Text>
      </BookAppointmentInfoTitleContainer>
      <BookAppointmentInfoContainer>
        <CustomList items={getAppointmentsOfferString()} type="bulleted" />
      </BookAppointmentInfoContainer>

      <ButtonsContainer>
        <Button
          category="primary-dark"
          size="lg"
          fullWidth={true}
          label="Book an Appointment"
          onClick={() => history.push(pageUrl.booking.bookingQuiz())}
        />
      </ButtonsContainer>
    </IllustratedBackgroundWidget>
  );
};
