import { useQuery } from '@apollo/client';
import moment, { Moment as TMoment } from 'moment';
import React, { useState, useEffect } from 'react';

import { Loader } from 'components/Loader/Loader';
import { usePatient } from 'hooks';
import { TimeSlot } from 'kb-shared';
import { mappedTimeZone, MOMENT_TIME_ZONES } from 'kb-shared/utilities/momentHelpers';

import { AdditionalInfoPanel } from '../AdditionalInfoPanel';
import { dateTimeSlotsQuery } from './BookingCalendarTime.graphql';
import { FallbackWrapper, BookingCalendarTimeContainer } from './BookingCalendarTime.styled';
import { Props, TimeSlotsResults } from './BookingCalendarTime.types';
import { AdditionalInfo } from './components/AdditionalInfo/AdditionalInfo';
import BookingCalendar from './components/BookingCalendar';
import BookingTimeSelection from './components/BookingTimeSelection';
import { DEFAULT_FALLBACK } from './MissingTimeSlotsFallback';

const requiresPointerToDoctorPages = (appointmentTypeId: number): boolean => {
  return (
    appointmentTypeId === 93 ||
    appointmentTypeId === 143 ||
    appointmentTypeId === 351 ||
    appointmentTypeId === 157
  );
};

export const BookingCalendarTime = (props: Props): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<TMoment | undefined>();
  const [timeSlotsMonthLoadAttempt, setTimeSlotsMonthLoadAttempt] = useState(0);
  const { patient } = usePatient();
  const preferredTimezone = patient?.preferredTimezone as keyof typeof MOMENT_TIME_ZONES;
  const labTimezone = patient?.lab?.timeZone;

  const [currentMonth, setCurrentMonth] = useState(
    moment.tz(mappedTimeZone(preferredTimezone || labTimezone || 'UTC'))
  );
  const [appointmentFound, setAppointmentFound] = useState<boolean>(false);
  const today = moment();

  const adjustSelectedDate = (selectedDate: TMoment) => {
    setSelectedDate(selectedDate);
    props.onSelectTimeSlot(null);
  };

  const adjustCurrentMonth = (adjustMonth: number) => {
    setSelectedDate(undefined);
    setCurrentMonth(currentMonth.clone().add(adjustMonth, 'months'));
    setTimeSlotsMonthLoadAttempt(timeSlotsMonthLoadAttempt + 1);
    props.onSelectTimeSlot(null);
  };

  const cleanResults = (data: Array<TimeSlot>) => {
    return data.reduce((allSlots: Array<TimeSlot>, currentTimeSlot: TimeSlot) => {
      const utcStartTime = moment(currentTimeSlot.startTime).utc();

      const hasTimeSlot = allSlots.find(timeSlot => utcStartTime.isSame(timeSlot.utcStartTime));

      if (!hasTimeSlot) {
        allSlots.push({
          ...currentTimeSlot,
          utcStartTime
        });
      }
      return allSlots;
    }, []);
  };

  const findSelectedTimeSlots = (allSlots: Array<TimeSlot>) => {
    const selectedAvailableTimeSlots = allSlots.filter(timeSlot => {
      return selectedDate?.date() === moment.parseZone(timeSlot.startTime).date();
    });
    return selectedAvailableTimeSlots;
  };

  const locationId =
    typeof props.locationId === 'string' ? parseInt(props.locationId) : props.locationId;

  const isVirtual = props.appointmentType.virtual;
  const appointmentTypeId = parseInt(props.appointmentType.id);

  const { data, loading } = useQuery<TimeSlotsResults>(dateTimeSlotsQuery, {
    variables: {
      appointmentTypeId: appointmentTypeId,
      locationId: locationId ? locationId : null,
      startDateTime: currentMonth?.clone().startOf('month') || moment().startOf('month'),
      endDateOffsetInMonths: 1,
      labId: props.labId,
      providerId: props.providerId,
      appointmentId: props.appointmentId
    }
  });

  const isLoadingTimeSlots = loading || timeSlotsMonthLoadAttempt > 0;

  const getSelectedDate = (): TMoment | undefined => {
    if (!data) return;

    if (props.date) return moment(props.date);

    if (data.dateTimeSlots.length > 0) {
      return moment(data.dateTimeSlots[0].startTime).startOf('day');
    } else {
      return currentMonth.clone().startOf('month');
    }
  };

  useEffect(() => {
    if (!data || !data.dateTimeSlots || loading || selectedDate) return;
    const dateTimeSlots = cleanResults(data.dateTimeSlots);
    let selectedStartDate = getSelectedDate();

    if (
      selectedStartDate?.date() === today.date() &&
      selectedStartDate?.month() === today.month() &&
      !props.currentDayCanBeSelected
    ) {
      selectedStartDate = selectedStartDate.clone().add(1, 'days');
    }

    setSelectedDate(selectedStartDate);

    if (!appointmentFound && !dateTimeSlots?.length) {
      // try to load time slots for the next month, up to a year ahead
      if (timeSlotsMonthLoadAttempt < 12) {
        adjustCurrentMonth(1);
      } else {
        setTimeSlotsMonthLoadAttempt(0);
      }
      return;
    }

    setTimeSlotsMonthLoadAttempt(0);
    setAppointmentFound(true);
    props.onAppointmentsFound?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, currentMonth, loading, selectedDate, adjustCurrentMonth, appointmentFound, today]);

  const renderCalendarContent = () => {
    if (isLoadingTimeSlots) return <Loader container />;

    if (!appointmentFound) {
      return <FallbackWrapper>{DEFAULT_FALLBACK}</FallbackWrapper>;
    }

    if (!data || !data.dateTimeSlots) return null;

    const dateTimeSlots = cleanResults(data.dateTimeSlots);
    const availableTimeSlots = findSelectedTimeSlots(dateTimeSlots);

    return (
      <>
        <BookingCalendar
          // @ts-ignore
          currentMonth={currentMonth}
          // @ts-ignore
          selectedDate={selectedDate}
          timeSlotsResults={dateTimeSlots}
          onPrevious={() => {
            adjustCurrentMonth(-1);
          }}
          onNext={() => {
            adjustCurrentMonth(1);
          }}
          onSelectDate={(newDate: TMoment) => {
            adjustSelectedDate(newDate);
          }}
          renderBorder={true}
          currentDayCanBeSelected={props.currentDayCanBeSelected}
        />
        <BookingTimeSelection
          selectedTimeSlot={props.selectedTimeSlot}
          onSelectTimeSlot={props.onSelectTimeSlot}
          timeslots={availableTimeSlots}
          date={selectedDate}
          selectedLabTimeZone={props.selectedLabTimeZone}
          appointmentType={props.appointmentType}
        />
      </>
    );
  };

  const showBottomSection = !props.providerId && appointmentFound;

  return (
    <>
      <BookingCalendarTimeContainer>{renderCalendarContent()}</BookingCalendarTimeContainer>

      {showBottomSection && (
        <AdditionalInfoPanel>
          <AdditionalInfo
            requiresPointerToDoctorPages={requiresPointerToDoctorPages(appointmentTypeId)}
            isVirtual={isVirtual}
          />
        </AdditionalInfoPanel>
      )}
    </>
  );
};
