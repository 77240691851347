import { utils } from 'kb-shared';

const { KBContacts, labs } = utils;

export interface MoreQuestionsLab {
  labelText?: string;
  number?: string;
  textingNumberAvailable?: boolean;
  textingNumber?: string;
  nightDirectNumber?: string;
}

export const LABS: Record<string, MoreQuestionsLab> = Object.freeze({
  '0': {
    labelText: KBContacts.nyWhatsappNumber.replace('-', ' ').slice(4),
    number: KBContacts.nyWhatsappNumber
  },
  [labs().NY]: {
    labelText: KBContacts.nyWhatsappNumber.replace('-', ' ').slice(4),
    number: KBContacts.nyWhatsappNumber,
    textingNumberAvailable: true,
    textingNumber: KBContacts.easternTextingNumber,
    nightDirectNumber: KBContacts.easternNightDirectNumber
  },
  [labs().SF]: {
    labelText: KBContacts.sfWhatsappNumber.replace('-', ' ').slice(4),
    number: KBContacts.sfWhatsappNumber,
    textingNumberAvailable: true,
    textingNumber: KBContacts.caTextingNumber,
    nightDirectNumber: KBContacts.caNightDirectNumber
  },
  [labs().LA]: {
    labelText: KBContacts.laWhatsappNumber.replace('-', ' ').slice(4),
    number: KBContacts.laWhatsappNumber,
    textingNumberAvailable: true,
    textingNumber: KBContacts.caTextingNumber,
    nightDirectNumber: KBContacts.caNightDirectNumber
  },
  [labs().SILICON_VALLEY]: {
    labelText: KBContacts.siliconValleyWhatsappNumber.replace('-', ' ').slice(4),
    number: KBContacts.siliconValleyWhatsappNumber,
    textingNumberAvailable: true,
    textingNumber: KBContacts.caTextingNumber,
    nightDirectNumber: KBContacts.caNightDirectNumber
  },
  [labs().ATLANTA]: {
    labelText: KBContacts.atlantaWhatsappNumber.replace('-', ' ').slice(4),
    number: KBContacts.atlantaWhatsappNumber
  },
  [labs().AUSTIN]: {
    labelText: KBContacts.austinWhatsappNumber.replace('-', ' ').slice(4),
    number: KBContacts.austinWhatsappNumber,
    textingNumberAvailable: true,
    textingNumber: KBContacts.texasTextingNumber,
    nightDirectNumber: KBContacts.texasNightDirectNumber
  },
  [labs().HOUSTON]: {
    textingNumberAvailable: true,
    textingNumber: KBContacts.texasTextingNumber,
    nightDirectNumber: KBContacts.texasNightDirectNumber
  },
  [labs().DALLAS]: {
    textingNumberAvailable: true,
    textingNumber: KBContacts.texasTextingNumber,
    nightDirectNumber: KBContacts.texasNightDirectNumber
  },
  [labs().MINNEAPOLIS]: {
    labelText: KBContacts.minneapolisWhatsappNumber.replace('-', ' ').slice(4),
    number: KBContacts.minneapolisWhatsappNumber,
    textingNumberAvailable: true,
    textingNumber: KBContacts.centralTextingNumber,
    nightDirectNumber: KBContacts.centralNightDirectNumber
  },
  [labs().ARKANSAS]: {
    textingNumberAvailable: true,
    textingNumber: KBContacts.centralTextingNumber,
    nightDirectNumber: KBContacts.centralNightDirectNumber
  },
  [labs().DENVER]: {
    textingNumberAvailable: true,
    textingNumber: KBContacts.centralTextingNumber,
    nightDirectNumber: KBContacts.centralNightDirectNumber
  },
  [labs().DC]: {
    textingNumberAvailable: true,
    textingNumber: KBContacts.southernTextingNumber,
    nightDirectNumber: KBContacts.southernNightDirectNumber
  },
  [labs().ATLANTA]: {
    textingNumberAvailable: true,
    textingNumber: KBContacts.southernTextingNumber,
    nightDirectNumber: KBContacts.southernNightDirectNumber
  },
  [labs().PRINCETON]: {
    textingNumberAvailable: true,
    textingNumber: KBContacts.easternTextingNumber,
    nightDirectNumber: KBContacts.easternNightDirectNumber
  },
  [labs().PORTLAND]: {
    textingNumberAvailable: false,
    textingNumber: KBContacts.portlandNumber,
    nightDirectNumber: KBContacts.portlandNumber
  },
  [labs().MILWAUKEE]: {
    textingNumberAvailable: true,
    textingNumber: KBContacts.centralTextingNumber,
    nightDirectNumber: KBContacts.centralNightDirectNumber
  },
  [labs().CHICAGO]: {
    textingNumberAvailable: false,
    number: KBContacts.chicagoDirectNumber,
    textingNumber: KBContacts.easternNightDirectNumber,
    nightDirectNumber: KBContacts.easternNightDirectNumber
  }
});
