import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useHomeMarket from 'hooks/useHomeMarket';
import { ReduxState } from 'kb-redux';
import { themes } from 'kb-shared';
import { hasMembershipDocument } from 'screens/Dashboard/Dashboard.utils';
import { pageUrl } from 'utilities/pageUrl';
import { useBreakpoints } from 'utilities/useBreakpoints';
import { getUserDisplayName } from 'utilities/userUtil';

import { CollapsedNavigationItems } from '../CollapsedNavigation/CollapsedNavigationItems';
import { ExpandedNavigationItems } from '../ExpandedNavigation/ExpandedNavigationItems';
import { HamburgerMenu } from '../HamburgerMenu/HamburgerMenu';
import { menuItems } from '../menuItems';
import { UNREAD_MESSAGES_COUNT } from './Navigation.graphql';
import { NavigationProps } from './Navigation.types';

export const Navigation = ({
  isMenuOpen,
  toggleMenu,
  patient,
  onDisableMenuClose,
  onEnableMenuClose
}: NavigationProps) => {
  const { isMobile } = useBreakpoints();
  const location = useLocation();
  const { vios: isViosPatient } = useHomeMarket();
  const isLoggedIn = useSelector<ReduxState>(state => state.patient.isLoggedIn);

  const { data, startPolling, stopPolling } = useQuery(UNREAD_MESSAGES_COUNT, {
    skip: !isLoggedIn
  });

  useEffect(() => {
    isLoggedIn ? startPolling(10000) : stopPolling();
    return () => stopPolling();
  }, [isLoggedIn, startPolling, stopPolling]);

  // navigation is used on the SimpleLayout component, which is used inside booking flow.
  // We don't want to show the navigation if the user is unauthenticated in the booking flow
  if (!isLoggedIn || patient == null) return null;

  const isDashboard = location.pathname === pageUrl.dashboard();

  return (
    <>
      <HamburgerMenu
        onClick={toggleMenu}
        name={getUserDisplayName(patient)}
        $height={isDashboard ? 64 : 52}
        $backgroundColor={isDashboard ? themes.colors.yellow.primary : themes.colors.white}
        isDashboard={isDashboard}
        hasMembershipDocument={hasMembershipDocument(patient)}
        documentUrl={patient?.patientMembership?.membership?.documentUrl}
      />
      {!isMobile && !isMenuOpen && (
        <CollapsedNavigationItems
          toggleMenu={toggleMenu}
          currentPathName={location.pathname}
          numberOfUnreadMessages={data?.unreadMessagesCount}
          items={isViosPatient ? menuItems.filter(item => !item.hideForVios) : menuItems}
        />
      )}
      {isMenuOpen && (
        <ExpandedNavigationItems
          toggleMenu={toggleMenu}
          currentPathName={location.pathname}
          numberOfUnreadMessages={data?.unreadMessagesCount}
          items={isViosPatient ? menuItems.filter(item => !item.hideForVios) : menuItems}
          patient={patient}
          onDisableMenuClose={onDisableMenuClose}
          onEnableMenuClose={onEnableMenuClose}
        />
      )}
    </>
  );
};
