import { gql, useMutation, useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import React, { useState } from 'react';

import { Button } from 'components/v2/Buttons/Button';
import { FinishModal } from 'screens/Dashboard/PeriodTracker/components/FinishModal/FinishModal';
import styled from 'styled-components';
import { showErrorToast } from 'utilities/notificationUtils';

import { Description, Title } from './styled-components';

const USER_PERIOD = gql`
  query lastPeriod {
    lastPeriod {
      periodDate
      id
    }
  }
`;

const CREATE_PERIOD = gql`
  mutation createPeriod($periodDate: String!) {
    createPeriod(periodDate: $periodDate) {
      period {
        periodDate
        id
      }
    }
  }
`;

const LogYourPeriodSubstep = () => {
  const [date, setDate] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useQuery(USER_PERIOD, {
    onCompleted: data =>
      !date &&
      data?.lastPeriod?.periodDate &&
      setDate(moment(data.lastPeriod.periodDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))
  });

  const [createPeriod] = useMutation(CREATE_PERIOD, {
    onCompleted: ({ createPeriod }) => {
      const periodDate = createPeriod.period.periodDate;
      setDate(
        moment(periodDate)
          .utc()
          .format('YYYY-MM-DD')
      );
      setDisabled(false);
    }
  });

  return (
    <>
      <Title id="period-start-date-title">Period start date</Title>
      <Description>
        Let us know as soon as you get your period. Once we know the date for your period, we can
        start to plan the visit dates for your cycle.
      </Description>
      <InputWrapper>
        <DateInput
          aria-labelledby="period-start-date-title"
          type="date"
          onChange={({ target }) => setDate(target.value)}
          value={date}
        />
        <SmallOutlineButton
          onClick={() => {
            if (moment(date).isValid()) {
              setDisabled(true);
              createPeriod({ variables: { periodDate: date } });
              setShowModal(true);
            } else {
              showErrorToast('Date is not valid');
            }
          }}
          isDisabled={disabled}
          label="Log Your Period"
        />
      </InputWrapper>

      <FinishModal visible={showModal} dismiss={() => setShowModal(false)} />
    </>
  );
};

export default LogYourPeriodSubstep;

const DateInput = styled.input`
  ${({ theme }) => theme.fonts.DomaineDisplay};
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-size: 24px;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.primaryNavy};
  max-width: 200px;
  margin-right: 10px;
`;

const SmallOutlineButton = styled(Button).attrs({ category: 'secondary', size: 'md' })`
  min-width: fit-content;
  height: fit-content;
  width: fit-content;
  white-space: nowrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    margin-top: 10px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    margin-bottom: 28px;
  }
`;
