import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { notify } from 'react-notify-toast';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { usePatient } from 'hooks';
import { utils, FormSection, IntakeForm } from 'kb-shared';
import { INTAKE_COMPLETE_FORM, INTAKE_SAVE_ANSWER } from 'kb-shared/graphql/mutations';
import { INTAKE_FORM } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { FormAnswers } from 'kb-shared/utilities/intake_form.helper';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';
import { showErrorToast } from 'utilities/notificationUtils';

import { FormRenderer } from '../components/FormRenderer/FormRenderer';
import Header from '../components/Header/Header';
import { ProgressFooter } from '../components/ProgressFooter/ProgressFooter';
import SectionIndicator from '../components/SectionIndicator';
import {
  isFormField,
  scrollToFormElement,
  getQuestionIdsToHide,
  validateDataTypes,
  getNewFormState,
  scrollToQuestion
} from './IntakeFormRoute.helpers';
import {
  BackgroundColor,
  Container,
  Content,
  FormContainer,
  SectionContainer,
  StickyHeader,
  StickyFooter,
  HEADER_FOOTER_HEIGHT
} from './IntakeFormRoute.styled';
import {
  FetchIntakeFormResponse,
  FormCompletionResponse,
  FormCompletionVariables,
  FormElement,
  Props,
  SaveAnswerResponse,
  SaveAnswerVariables
} from './IntakeFormRoute.types';

export const IntakeFormRoute = ({ onFormBecomeUnavailable }: Props) => {
  const history = useHistory();
  const [currentFormSectionIndex, setCurrentFormSectionIndex] = useState(0);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [progress, setProgress] = useState<number | null>(null);
  const [formStructure, setFormStructure] = useState<IntakeForm>({
    id: '',
    title: '',
    formSections: [],
    conditionalFormElements: []
  });
  const [answers, setAnswers] = useState<FormAnswers | null>(null);
  const [invalidQuestions, setInvalidQuestions] = useState<string[]>([]);

  const formRendererRef: React.RefObject<FormRenderer> = React.createRef();

  const [completeIntakeForm] = useMutation<FormCompletionResponse, FormCompletionVariables>(
    INTAKE_COMPLETE_FORM
  );
  const [saveAnswer] = useMutation<SaveAnswerResponse, SaveAnswerVariables>(INTAKE_SAVE_ANSWER);

  const isGyn = window.location.search.indexOf('gyn') > -1;
  const patient = usePatient();
  const INTAKE_FORM_ID = utils.intakeFormId(patient.patient?.gender);
  const { loading: loadingForm } = useQuery<FetchIntakeFormResponse>(INTAKE_FORM, {
    variables: { formId: INTAKE_FORM_ID, appointmentType: isGyn ? 'gyn' : null },
    skip: !patient.patient?.gender,
    onError: error => BugTracker.notify(error, 'IntakeFormLoading'),
    onCompleted: formData => {
      let questionsWithInvalidDataType: string[] = [];
      if (formData?.patientForm) {
        questionsWithInvalidDataType = validateDataTypes(formData.patientForm);

        const questions = utils.normalizeIntakeForm(formData.patientForm);
        setFormStructure(questions);

        if (formData?.formCompletion?.answers) {
          setProgress(formData.formCompletion.percentageComplete);
          const questionsAnswers = utils.mapIntakeFormAnswers(formData.formCompletion.answers);
          setAnswers(questionsAnswers);
          const newFormState = getNewFormState(
            questions,
            questionsAnswers,
            questionsWithInvalidDataType
          );
          if (newFormState) {
            updateDisplayedForm(newFormState.newSectionIndex, newFormState.newQuestionIndex);
          }
        } else {
          setAnswers({});
        }

        setInvalidQuestions(questionsWithInvalidDataType);
      }
    }
  });

  useEffect(() => {
    const { formSections } = formStructure;
    const currentSection = formSections[currentFormSectionIndex];
    if (currentSection == null) return;

    const displayedElements = currentSection.formElements.filter(
      element =>
        !getQuestionIdsToHide(
          formStructure.formSections[currentFormSectionIndex],
          formStructure.conditionalFormElements || [],
          answers
        ).includes(element.id)
    );
    if (currentFormIndex > displayedElements.length - 1) return;
    const questionToScrollTo = displayedElements[currentFormIndex];

    if (questionToScrollTo) scrollToQuestion(questionToScrollTo.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFormSectionIndex, currentFormIndex]);

  const getAnswer = (formElement: FormElement) => {
    if (answers == null) return null;
    return answers[formElement.id];
  };

  const updateDisplayedForm = (sectionIndex: number, formIndex: number) => {
    setCurrentFormSectionIndex(sectionIndex);
    setCurrentFormIndex(formIndex);
  };

  const currentSection = (): FormSection | null => {
    return formStructure.formSections[currentFormSectionIndex];
  };

  const isAnswerMissing = (formElement: FormElement) => {
    if (answers == null) return true;
    if (
      getQuestionIdsToHide(
        formStructure.formSections[currentFormSectionIndex],
        formStructure.conditionalFormElements || [],
        answers
      ).includes(formElement.id)
    ) {
      // If question which answer is checked is question that needs to be hidden,
      // it is irrelevant if answer is missing for the question.
      return false;
    }
    const existingAnswer = getAnswer(formElement);
    const existingAnswerIsMissing =
      existingAnswer == null || !existingAnswer.data || existingAnswer.data === '[]';
    const newAnswer = answers[formElement.id];
    const newAnswerIsMissing = newAnswer == null || !newAnswer.data || newAnswer.data === '[]';
    return existingAnswerIsMissing && newAnswerIsMissing;
  };

  const getFirstRequiredFormElementMissingAnswer = (currentSection: FormSection | null) => {
    if (currentSection == null) {
      return;
    }

    const formElementsWithRequiredAnswer = currentSection.formElements.filter(
      el => isFormField(el) && el.required
    );

    if (formElementsWithRequiredAnswer == null || formElementsWithRequiredAnswer.length === 0) {
      return;
    }

    return formElementsWithRequiredAnswer.find(el => isAnswerMissing(el));
  };

  const notifyAboutMissingAnswer = (formElementMissingAnswer: FormElement) => {
    showErrorToast(
      'To continue, please answer any mandatory questions marked with a red asterisk.'
    );
    scrollToFormElement(formElementMissingAnswer.id);
  };

  const showNextSection = () => {
    const formElementWithMissingAnswer = getFirstRequiredFormElementMissingAnswer(currentSection());
    if (formElementWithMissingAnswer) {
      notifyAboutMissingAnswer(formElementWithMissingAnswer);
      return;
    }

    updateDisplayedForm(currentFormSectionIndex + 1, 0);
  };

  const showPreviousSection = () => {
    updateDisplayedForm(currentFormSectionIndex - 1, 0);
  };

  const calculateProgress = (): number => {
    if (!progress) {
      return 0;
    }
    return progress / 100;
  };

  const submitAnswer = async (answerValue: string | Array<string> | undefined, id: string) => {
    try {
      const saveAnswerVariables: SaveAnswerVariables = {
        formId: INTAKE_FORM_ID,
        formElementId: parseInt(id)
      };
      if (Array.isArray(answerValue)) {
        saveAnswerVariables.dataArray = answerValue;
      } else {
        saveAnswerVariables.data = answerValue;
      }

      const savedAnswer = await saveAnswer({
        variables: saveAnswerVariables
      });

      if (savedAnswer.data?.createAnswer?.answer) {
        const answer = savedAnswer.data.createAnswer.answer;
        setProgress(savedAnswer.data?.createAnswer?.percentageComplete);
        setAnswers({ ...answers, [answer.formElementId]: answer });
      } else if (
        savedAnswer.data?.createAnswer?.errors === 'FORM_ALREADY_COMPLETED' ||
        savedAnswer.data?.createAnswer.errors === 'FORM_NOT_AVAILABLE'
      ) {
        onFormBecomeUnavailable();
      }
    } catch (e) {
      BugTracker.notify(e, 'IntakeFormSaveAnswer');
      notify.show('Failed to save the answer. Please try again.', 'custom', 10000, {
        background: '#e57373',
        text: '#FFFFFF'
      });
    }
  };

  const onSubmitForm = async () => {
    try {
      const formCompletionResponse = await completeIntakeForm({
        variables: { formId: INTAKE_FORM_ID, completedAt: moment().toISOString() }
      });
      if (formCompletionResponse.data?.updateFormCompletion.formCompletion != null) {
        history.push('/');
      } else if (
        formCompletionResponse.data?.updateFormCompletion.errors === 'FORM_ALREADY_COMPLETED' ||
        formCompletionResponse.data?.updateFormCompletion.errors === 'FORM_NOT_AVAILABLE'
      ) {
        onFormBecomeUnavailable();
      } else if (formCompletionResponse.data?.updateFormCompletion.errors != null) {
        reportFormCompletingError(formCompletionResponse.data?.updateFormCompletion.errors);
      }
    } catch (e) {
      reportFormCompletingError(e);
    }
  };

  const renderForm = () => {
    if (answers == null) return null;

    return (
      <FormRenderer
        ref={formRendererRef}
        formData={formStructure}
        questionsToHide={getQuestionIdsToHide(
          formStructure.formSections[currentFormSectionIndex],
          formStructure.conditionalFormElements || [],
          answers
        ).concat(invalidQuestions)}
        currentSection={currentFormSectionIndex}
        currentForm={currentFormIndex}
        topOffset={HEADER_FOOTER_HEIGHT}
        showNextSection={showNextSection}
        showPreviousSection={showPreviousSection}
        onSubmitAnswer={(response: string | Array<string> | undefined, id: string) => {
          submitAnswer(response, id);
        }}
        savedAnswers={answers}
        onSubmitForm={onSubmitForm}
      />
    );
  };

  const showSelectedSection = (selectedSection: FormSection) => {
    const currSection = currentSection();
    const formElementWithMissingAnswer = getFirstRequiredFormElementMissingAnswer(currSection);
    if (formElementWithMissingAnswer) {
      notifyAboutMissingAnswer(formElementWithMissingAnswer);
      return;
    }

    const { formSections } = formStructure;
    const formSectionToShowIndex = formSections.findIndex(
      formSection => formSection.position === selectedSection.position
    );

    if (formSectionToShowIndex === -1) return;

    updateDisplayedForm(formSectionToShowIndex, 0);
  };

  const currentSectionIndex = currentFormSectionIndex + 1;
  const totalSections = formStructure.formSections.length;
  const currentFormSection = currentSection();
  const currentFormSectionTitle = currentFormSection?.title || '';

  if (loadingForm || answers == null || formStructure.id === '') {
    return <Loader absoluteCentered />;
  }

  return (
    <Container>
      <BackgroundColor />
      <StickyHeader>
        <Header
          sectionTitle={currentFormSectionTitle}
          currentSectionIndex={currentSectionIndex}
          totalSections={totalSections}
          percentageComplete={progress}
          sections={formStructure.formSections}
          selectedSection={currentFormSection}
          onSectionSelected={newSelectedSection => showSelectedSection(newSelectedSection)}
        />
      </StickyHeader>
      <Content>
        <SectionContainer>
          <SectionIndicator sections={totalSections} selectedSection={currentFormSectionIndex} />
        </SectionContainer>
        <FormContainer>{renderForm()}</FormContainer>
      </Content>
      <StickyFooter>
        <ProgressFooter percentComplete={calculateProgress()} />
      </StickyFooter>
    </Container>
  );
};

const reportFormCompletingError = (error: unknown) => {
  let errorMessage = `Sorry, we aren't able to complete the form. Please try again or contact us at ${KBContacts.navigatorEmail}`;
  if (error instanceof Error) {
    if (error.message.includes('Please fill out all of the required fields')) {
      errorMessage = 'Please fill out all of the required fields.';
    } else if (error.message.includes('Form completion could not be found')) {
      errorMessage = 'Please fill out all of the required fields.';
    } else {
      BugTracker.notify(error, 'IntakeFormSubmit');
    }
  } else {
    BugTracker.notify(error, 'IntakeFormSubmit');
  }

  notify.show(errorMessage, 'custom', 10000, {
    background: '#e57373',
    text: '#FFFFFF'
  });
};
