/**
 * Part of the LA-Clinic roll out, we are temporarily providing
 * users the choice of two clinics. The LA clinic will redirect
 * users away from our site, so this is temporary.
 */
import invert from 'lodash.invert';

import { TemporaryClinic } from '../types';
import { labs } from './lab.helper';

const clinicLocations: Array<TemporaryClinic> = [
  {
    id: 'MIDTOWN',
    name: 'Midtown',
    address: {
      name: '',
      street1: '693 5th Ave',
      street2: '7th Floor',
      city: 'New York',
      state: 'NY'
    }
  },
  {
    id: 'WESTWOOD',
    name: 'Westwood',
    address: {
      name: 'CMD Fertility (Kindbody Partner)',
      street1: '10921 Wilshire Blvd',
      street2: '#702',
      city: 'Los Angeles',
      state: 'CA'
    }
  }
];

const KBContacts = {
  // default numbers
  employeeBenefitsEmail: 'employeebenefits@kindbody.com',
  defaultNumber: '018555632639',
  navigatorEmail: 'navigator@kindbody.com',
  navigatorNumber: '+01 (855) 563-2639',

  // default values
  clinical: 'clinical@kindbody.com',
  clinicLocations,

  // regional numbers
  centralTextingNumber: '(720) 903-1535',
  centralNightDirectNumber: '(303) 285-2292',
  southernTextingNumber: '(404) 991-6278',
  southernNightDirectNumber: '(770) 650-3393',
  easternTextingNumber: '(609) 293-3480',
  easternNightDirectNumber: '(212) 417-0474',

  // market specific numbers
  nyWhatsappNumber: '+01 (628) 629-2436',
  sfWhatsappNumber: '+01 (628) 219-1215',
  laWhatsappNumber: '+01 (310) 857-9390',
  minneapolisWhatsappNumber: '+01 (415) 940-0894',
  siliconValleyWhatsappNumber: '+01 (650) 407-4181',
  atlantaWhatsappNumber: '+01 (470) 453-6446',
  austinWhatsappNumber: '+01 (737) 268-8838',
  caTextingNumber: '(315) 659-9094',
  caNightDirectNumber: '(628) 867-8677',
  texasTextingNumber: '(210) 908-5258',
  texasNightDirectNumber: '(737) 292-6030',
  portlandNumber: '(360) 535 7590',
  portlandEmail: 'pnw.pc@kindbody.com',
  chicagoDirectNumber: '(773)-435-9029'
};

const market = labs();

const defaultMarketInfo = {
  numberLabel: '855 KND BODY',
  number: KBContacts.navigatorNumber,
  email: KBContacts.navigatorEmail
};

type LabName = keyof typeof LABS;

const LABS = Object.freeze({
  NONE: {
    id: '0',
    ...defaultMarketInfo,
    nightDirectNumber: null
  },
  NY: {
    id: market.NY,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.easternNightDirectNumber
  },
  SF: {
    id: market.SF,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.caNightDirectNumber
  },
  LA: {
    id: market.LA,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.caNightDirectNumber
  },
  SILICON_VALLEY: {
    id: market.SILICON_VALLEY,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.caNightDirectNumber
  },
  ATLANTA: {
    id: market.ATLANTA,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.southernNightDirectNumber
  },
  AUSTIN: {
    id: market.AUSTIN,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.texasNightDirectNumber
  },
  MINNEAPOLIS: {
    id: market.MINNEAPOLIS,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.centralNightDirectNumber
  },
  ARKANSAS: {
    id: market.ARKANSAS,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.centralNightDirectNumber
  },
  HOUSTON: {
    id: market.HOUSTON,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.texasNightDirectNumber
  },
  DALLAS: {
    id: market.DALLAS,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.texasNightDirectNumber
  },
  DENVER: {
    id: market.DENVER,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.centralNightDirectNumber
  },
  DC: {
    id: market.DC,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.southernNightDirectNumber
  },
  PRINCETON: {
    id: market.PRINCETON,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.easternNightDirectNumber
  },
  PORTLAND: {
    id: market.PORTLAND,
    ...defaultMarketInfo,
    numberLabel: KBContacts.portlandNumber,
    number: KBContacts.portlandNumber,
    email: KBContacts.portlandEmail,
    nightDirectNumber: null
  },
  MILWAUKEE: {
    id: market.MILWAUKEE,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.centralNightDirectNumber
  },
  CHICAGO: {
    id: market.CHICAGO,
    ...defaultMarketInfo,
    nightDirectNumber: KBContacts.easternNightDirectNumber
  }
});

export function getLabInfo(labId: string | undefined = 'NONE') {
  const labsForEnvMap = labs();
  // Reverse keys/values
  const inverted = invert(labsForEnvMap);
  //@ts-ignore
  const locationAbbreviation: LabName = inverted[labId] || 'NONE';

  const contactInfo = locationAbbreviation && LABS[locationAbbreviation];
  const info = contactInfo || LABS.NONE;

  const hasNightDirectNumber = !!Object.values(LABS).find(
    i => i.id === labId && i.nightDirectNumber
  );

  return { ...info, nightNumberAvailable: hasNightDirectNumber };
}

export default KBContacts;
