import { ApolloError } from '@apollo/client';
import jwt from 'jsonwebtoken';

import { isFirstGraphQLErrorUnauthorized } from 'utilities/errors';

import { getIdToken } from './api';

/**
 * Provides a common interface for web/native bug tracker.
 * On web, we currently use Honeybadger
 *  */
export interface BugTrackerInterface {
  notify(...args: any[]): void;
}

const noopTracker: BugTrackerInterface = {
  notify: () => {}
};

class BugTrackerWrapper implements BugTrackerInterface {
  inner: BugTrackerInterface = noopTracker;

  notify(...args: any[]) {
    const [error] = args;

    // Skip reporting unauthorized errors if the token exists and is expired
    if (this.shouldSkipUnauthorizedError(error)) {
      return;
    }

    this.inner.notify(...args);
  }

  // check for unauthorized errors and verify if it's because of an expired token
  private shouldSkipUnauthorizedError(error: any): boolean {
    const isUnauthorizedErrorType =
      error instanceof ApolloError && isFirstGraphQLErrorUnauthorized(error);

    if (!isUnauthorizedErrorType) return false;

    const idToken = getIdToken() as string;
    const maybeJwt = jwt.decode(idToken) as jwt.JwtPayload | null;
    const hourMs = 60 * 60 * 1000;
    // We need to look at iat + 1hour for token expiry because not all auth services issue tokens with the same exp duration(SSO ones)
    // Our auth logic looks at iat + 1hour instead.
    const tokenIssuedAtMs = maybeJwt?.iat ? maybeJwt.iat * 1000 : 0;
    const isTokenExpired = tokenIssuedAtMs + hourMs < Date.now();

    return isTokenExpired;
  }
}

export const BugTracker = new BugTrackerWrapper();

export function setBugTracker(tracker: BugTrackerInterface) {
  BugTracker.inner = tracker;
}
