import React from 'react';

import { Calendar, Clock, Pin } from 'assets/icons/Icons';
import Initials from 'components/Initials';
import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import { getTimeZone } from 'hooks';
import { Appointment } from 'kb-shared';
import { shouldDisplayProvider } from 'kb-shared/utilities/appointment.helper';
import { DEFAULT_DATE_FORMAT, HOUR_WITH_TIMEZONE } from 'utilities/formatDate';
import { useBreakpoints } from 'utilities/useBreakpoints';

import {
  CheckIcon,
  Container,
  Date,
  DateAndTime,
  HeaderMobile,
  LisItemMobile,
  ListHeader,
  ListItem,
  ListItemInfo,
  QuestionIcon,
  Time,
  TodaysBadge
} from './List.styled';

export const List = ({
  appointments,
  onRescheduledClick,
  highlightTodaysListItems
}: {
  appointments: Appointment[];
  onRescheduledClick?: (appointment: Appointment) => void;
  highlightTodaysListItems?: boolean;
}) => {
  const { isMobile, isTablet } = useBreakpoints();

  if (isMobile || isTablet) {
    return (
      <Container>
        {appointments.map(item => {
          const { formatTime } = getTimeZone(item.timeZone, item.appointmentType);

          return (
            <LisItemMobile highlightTodaysListItems={highlightTodaysListItems} key={item.id}>
              {highlightTodaysListItems && <TodaysBadge />}
              <HeaderMobile>
                {shouldDisplayProvider(item) && item.providerName && (
                  <Initials name={item.providerName} size="md" />
                )}
                <div>
                  <Text tag="p" fontStyle="semibold" multilineEllipses={2}>
                    {item.closureStatus === 'checked_out' && <CheckIcon />}
                    {item.closureStatus === 'no_show' && <QuestionIcon />}
                    {item.appointmentType.name}
                  </Text>
                  {shouldDisplayProvider(item) && item.providerName && (
                    <>
                      <Text>with</Text> <Text fontStyle="semibold">{item.providerName}</Text>
                    </>
                  )}
                </div>
              </HeaderMobile>
              <DateAndTime>
                <Date>
                  <Calendar type="regular" />{' '}
                  <Text tag="time" fontStyle={highlightTodaysListItems ? 'semibold' : 'regular'}>
                    {highlightTodaysListItems
                      ? 'Today'
                      : formatTime(item.startTime, DEFAULT_DATE_FORMAT)}
                  </Text>
                </Date>
                <Time>
                  <Clock type="regular" />
                  <Text tag="time" fontStyle={highlightTodaysListItems ? 'semibold' : 'regular'}>
                    {formatTime(item.startTime, HOUR_WITH_TIMEZONE)}
                  </Text>
                </Time>
              </DateAndTime>
              {onRescheduledClick && (
                <Button
                  label="Reschedule"
                  category="danger"
                  onClick={() => onRescheduledClick(item)}
                />
              )}
            </LisItemMobile>
          );
        })}
      </Container>
    );
  }

  return (
    <Container>
      <ListHeader>
        <Text size="sm">Name</Text>
        <Text size="sm">Date and Time</Text>
        <Text size="sm">Location and Provider</Text>
      </ListHeader>
      {appointments.map(item => {
        const { formatTime } = getTimeZone(item.timeZone, item.appointmentType);

        return (
          <ListItem highlightTodaysListItems={highlightTodaysListItems} key={item.id}>
            <Text multilineEllipses={2}>
              {item.closureStatus === 'checked_out' && <CheckIcon />}
              {item.closureStatus === 'no_show' && <QuestionIcon />}
              {highlightTodaysListItems && <TodaysBadge />}
              {item.appointmentType.name}
            </Text>
            <ListItemInfo>
              <Calendar type="regular" />
              <Text tag="time" fontStyle={highlightTodaysListItems ? 'semibold' : 'regular'}>
                {highlightTodaysListItems ? 'Today' : formatTime(item.startTime, 'ddd, MMM D')}
              </Text>
              <Clock type="regular" />
              <Text tag="time" fontStyle={highlightTodaysListItems ? 'semibold' : 'regular'}>
                {formatTime(item.startTime, 'hh:mm A z')}
              </Text>
            </ListItemInfo>
            <ListItemInfo>
              <Pin type="regular" />
              <Text multilineEllipses={1}>{item.location.name}</Text>
              {shouldDisplayProvider(item) && item.providerName && (
                <>
                  <Initials name={item.providerName} />
                  <Text multilineEllipses={1}>{item.providerName}</Text>
                </>
              )}
            </ListItemInfo>

            {onRescheduledClick && (
              <Button
                label="Reschedule"
                category="danger"
                onClick={() => onRescheduledClick(item)}
              />
            )}
          </ListItem>
        );
      })}
    </Container>
  );
};
