import React from 'react';

import { MessageSms, PhoneOffice, Whatsapp } from 'assets/icons/Icons';
import { Heading, Text } from 'components/v2/Typography';
import { isPortlandPatient, isChicagoPatient } from 'kb-shared/utilities/lab.helper';

import { MoreQuestionsLab } from './IHaveMoreQuestionsModal.utils';
import {
  ModalContentContainer,
  PhoneNumberContainer,
  SmsNumberContainer,
  WhatsappNumberContainer
} from './ModalContent.styled';

export const ModalContent = ({ marketId, lab }: { marketId: string; lab: MoreQuestionsLab }) => {
  const isPortlandMarket = isPortlandPatient(marketId);
  const isChicagoMarket = isChicagoPatient(marketId);

  if (isChicagoMarket) {
    const number = lab?.number || '';

    return (
      <ModalContentContainer>
        <Text>Please call our office at</Text>
        <PhoneNumberContainer>
          <PhoneOffice type="solid" />
          <Heading tag="h2">
            <a href={`tel://${number}`}>{number}</a>
          </Heading>
        </PhoneNumberContainer>
      </ModalContentContainer>
    );
  }

  if (isPortlandMarket) {
    const number = lab?.textingNumber || '';
    return (
      <ModalContentContainer>
        <Text>If you have a question about your instructions, please call us at</Text>
        <PhoneNumberContainer>
          <PhoneOffice type="solid" />
          <Heading tag="h2">
            <a href={`tel://${number}`}>{number}</a>
          </Heading>
        </PhoneNumberContainer>
      </ModalContentContainer>
    );
  }

  if (lab?.textingNumberAvailable) {
    const textingNumber = lab.textingNumber;
    const nightDirectNumber = lab.nightDirectNumber;
    return (
      <ModalContentContainer>
        <Text>If you have an urgent question about your instructions, please text us at</Text>
        <SmsNumberContainer>
          <MessageSms type="solid" />
          <Heading tag="h2">
            <a href={`sms://${textingNumber}`}>{textingNumber}</a>
          </Heading>
        </SmsNumberContainer>

        <Text>If it is after 9:00 PM, the texting line is closed, so please call us at</Text>
        <PhoneNumberContainer>
          <PhoneOffice type="solid" />
          <Heading tag="h2">
            <a href={`tel://${nightDirectNumber}`}>{nightDirectNumber}</a>
          </Heading>
        </PhoneNumberContainer>
      </ModalContentContainer>
    );
  }

  const message = encodeURI('I have more questions.\nCan you help me?');
  return (
    <ModalContentContainer>
      <Text>Send a Whatsapp Message</Text>
      <WhatsappNumberContainer>
        <Whatsapp type="solid" />
        <Heading tag="h2">
          <a
            href={`https://api.whatsapp.com/send?phone=${lab.number}&text=${message}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {lab.labelText}
          </a>
        </Heading>
      </WhatsappNumberContainer>
    </ModalContentContainer>
  );
};
