import { themes } from 'kb-shared';
import styled from 'styled-components';

export const ImageRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ImageText = styled.div`
  ${({ theme }) => theme.fonts.Gotham};
  color: ${themes.colors.neutral.primaryNavy};
  font-size: 14px;
  margin-left: 20px;
  background: white;
`;
