import moment from 'moment';

export const HOUR_WITH_TIMEZONE = 'hh:mm A z';
export const DEFAULT_DATE_FORMAT = 'ddd, MMM D';

export const formatDate = (date: string | Date, format = 'MM/DD/YYYY') =>
  moment(date).format(format);

export const stringToDate = (date: string, format = 'MM/DD/YYYY') => moment(date, format).toDate();

export const dateToString = (date: Date) => date.toISOString().split('T')[0];

export const getUtcDate = (date: Date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000);

export const stringToDateUTC = (date: string) => new Date(`${date}T00:00:00`);

export const dateToISOFormat = (date: string | Date) => moment(date).toISOString();
