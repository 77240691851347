import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import React, { useState } from 'react';

import { Loader } from 'components/Loader/Loader';
import { PartnerClinicDetails, MessageType } from 'kb-shared';
import styled from 'styled-components';
import { showErrorToast } from 'utilities/notificationUtils';

import PartnerClinicCard from './PartnerClinicCard';

// Terms Block
const TermsBlock = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.neutral.navy};
  padding: 10px;
  margin-bottom: 28px;
  align-items: center;
  width: 90%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TermsTextContainer = styled.div`
  margin-bottom: 24px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

const TermsText = styled.span`
  ${({ theme }) => theme.fonts.FoundersGrotesk}
  font-size: 14px;
  color: white;
  font-style: italic;
`;

const PageTitle = styled.div`
  ${({ theme }) => theme.fonts.DomaineDisplay}

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  padding-top: 50px;
  padding-bottom: 30px;
  ${({ mobile }: PageAndTitleProps) => `
    font-size: ${mobile ? '28px' : '36px'};
    max-width: ${mobile ? '350px' : 'auto'};
    padding-bottom: ${mobile ? '15px' : '0px'}
  `};
`;

interface PageAndTitleProps {
  mobile?: boolean;
}

interface Props {
  partnerClinicSelected: PartnerClinicDetails;
  mobileLayout: boolean;
  appointmentName?: string;
  afterSend: () => void;
}

const PLACE_HOLDER_TEXT =
  'Please tell us a few details so we can best coordinate your care, such as when you are available and if you are an established patient at a clinic. Thank you!';
const createConversation = gql`
  mutation createConversation($category: String!, $subject: String!, $body: String!) {
    createConversation(category: $category, subject: $subject, body: $body) {
      conversation {
        id
      }
    }
  }
`;

const PartnerLocationRequest = (props: Props) => {
  const [body, setBody] = useState('');
  const [sendMessage, { loading }] = useMutation<MessageType>(createConversation);
  const { partnerClinicSelected, mobileLayout, appointmentName, afterSend } = props;

  function requestAppointment() {
    if (body.length === 0) {
      showErrorToast(
        'Please leave us some details about your availability so we can coordinate your appointment.'
      );
    } else {
      sendMessage({
        variables: {
          category: 'admin',
          subject: 'Partner Clinic Request',
          body: `${appointmentName} (${partnerClinicSelected.name}, ${partnerClinicSelected.city}, ${partnerClinicSelected.state}) - ${body}`,
          fileInfo: ''
        }
      })
        .then(() => {
          afterSend();
        })
        .catch(() =>
          showErrorToast('There was an error trying to request appointment. Please try again.')
        );
    }
  }

  return (
    <Container>
      <PartnerClinicCard partnerClinicDetails={partnerClinicSelected} />
      <div style={{ padding: '20px 0px' }}>
        <PageTitle mobile={mobileLayout}>What day & time works best for you?</PageTitle>
      </div>
      <Textarea
        rows={7}
        onChange={evt => setBody(evt.target.value)}
        placeholder={PLACE_HOLDER_TEXT}
      />
      <TermsBlock>
        <TextContainer>
          <TermsTextContainer>
            <TermsText>
              Members may be subject to additional cost-share at select Partner Clinics depending on
              their benefit design.
            </TermsText>
          </TermsTextContainer>
        </TextContainer>
      </TermsBlock>
      {loading ? (
        <Loader />
      ) : (
        <YellowButton onClick={() => requestAppointment()}>Request Appointment</YellowButton>
      )}
    </Container>
  );
};

const YellowButton = styled.button`
  ${({ theme }) => theme.fonts.FoundersGrotesk}
  text-transform: uppercase;
  font-size: 14px;
  padding: 15px 24px;
  background-color: ${({ theme }) => theme.colors.yellow.primary};
  border: 1px solid ${({ theme }) => theme.colors.yellow.primary};
  border-radius: 24px;
  cursor: pointer;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    width: 90%;
  }

  :hover,
  :focus {
    outline: 0;
    opacity: 0.8;

    /* When using the button as a 'a' element */
    font-weight: normal;
    text-decoration: none;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  max-width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largePhonePortrait}) {
    width: 100%;
  }
`;

const Textarea = styled.textarea`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  border: 1px solid ${({ theme }) => theme.deprecatedColors.gray};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.yellow.lightestYellow};
  padding: 10px;
  margin-bottom: 20px;
  min-height: 50px;
  min-width: 300px;
  width: 52%;

  font-size: 18px;
  resize: none;
  width: 90%;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.neutral.navy}:;
  }
`;

export default PartnerLocationRequest;
